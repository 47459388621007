// 'use strict';

// LIBS
import MicroModal from "micromodal"

// COMPONENTS
import { eventSlider } from "./components/eventSlider"
import { eventTabs } from './components/eventTabs'
import { newsSlider } from './components/newsSlider'
import { charSlider } from './components/charSlider'
import { faqAccordion } from './components/faqAccordion'


// Test$$$$$$$$$$
import { requestGet } from './components/requestGet'
// import { checkServer } from './components/checkServer';
import { requestStatus } from "./components/requestStatus"
import { generateEventSlide } from "./components/generateEventSlide"
import { emptyLink } from "./components/emtyLink"
import { eventTimer } from './components/eventTimer'
import Timer from "easytimer.js"
import { mainTimer } from "./components/mainTimer"
import { mediaSlider } from './components/mediaSlider'
import { popupAction } from './components/popupAction'
import chengingBgImg from './components/changingMainBg'
import checkLinks from './components/checkLinks'

// requestStatus.loading();

document.addEventListener('DOMContentLoaded', () => {



	// requestStatus.success();
	// ACTIVE
	MicroModal.init()


	//DOM elements

	chengingBgImg()


	faqAccordion()

	// Check
	emptyLink()

	// generateEventTabs();

	requestGet('https://mukeeper.com/eventTime.php?ajax=true')

	newsSlider()
	charSlider()
	mainTimer()
	eventSlider()
	eventTimer()

	let eventSliderParent = document.querySelector('.event__slider_wr')

	setInterval(function () {
		let activeEvent = document.querySelector('.event__slide_content_timer_value').dataset.time

		if (activeEvent == 0) {
			eventSliderParent.innerHTML = ''
			requestGet('https://mukeeper.com/eventTime.php?ajax=true')
			eventSlider()
			eventTimer()
		}
	}, 1000)




	requestGet('https://test.mukeeper.com/ru/statistic')
	eventTabs()

	mediaSlider()
	popupAction()
	checkLinks()
})







