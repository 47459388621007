import Swiper, { Navigation } from "swiper"


export const mediaSlider = () => {
	new Swiper('.media-slider', {
		modules: [Navigation],
		slidesPerView: 3,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.media-next',
			prevEl: '.media-prev',
		},
	})
}