import { Timer } from 'easytimer.js'
import { requestGet } from './requestGet'

export function eventTimer() {
	const allTimes = document.querySelectorAll('.event__slide_content_timer_value')

	if (allTimes.length >= 0) {
		allTimes.forEach(time => {
			var easytimer = new Timer()
			easytimer.start({
				countdown: true,
				startValues: {
					seconds: time.dataset.time
				},
				target: {
					seconds: 0
				}
			})
			easytimer.addEventListener('secondsUpdated', (e) => {
				time.innerHTML = easytimer.getTimeValues().toString(['days', 'hours', 'minutes', 'seconds'])
				time.dataset.time = easytimer.getTotalTimeValues().seconds

				// if (easytimer.getTimeValues().toString() == 0) {
				// 	console.log(easytimer.getTimeValues().toString())
				// }
			})





			easytimer.addEventListener('targetAchieved', (e) => {
				// requestGet('https://mukeeper.com/eventTime.php?ajax=true');

			})

		})
	}



}