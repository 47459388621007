export function popupAction() {
	const closeError = document.getElementById('closeNotFound')
	const modalNotFound = document.querySelector('.modal__not-found')

	// console.log(closeError)
	// console.log(modalNotFound)
	if (modalNotFound) {
		closeError.addEventListener("click", function () {
			modalNotFound.classList.remove("is-open")
		})
	}
}