import { checkServer } from './checkServer'

export default function checkLinks() {
	function clickHandler(event) {

		console.log(event)
		//  Проверяем наличие href
		//  Проверяем условия соответвия href (
		//  про
		// )
		//  Проверям доступность ссылки
		//  Если ссылка не доступна показать попап
		return false
	}
	// Cлушаем click по всем ссылкам
	const allLinks = document.getElementsByTagName("a")
	// console.log(allLinks)
	for (const link of allLinks) {
		link.addEventListener('click', checkServer)
	}
}