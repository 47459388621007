export function generateGuildsTab(obj) {

	const tabsWrapper = document.querySelector('.event__tabs_contents')

	let players = document.createElement('div')
	players.classList.add('event__tabs_content', 'players')

	let playersWrapper = document.createElement('div')
	playersWrapper.classList.add('event__tabs_content_wrap', 'players__wrapper')

	players.appendChild(playersWrapper)

	let users = { names: [], scores: [], masters: [] }
	for (const key in obj.topGuilds) {
		users.names.push(`<div title="${obj.topGuilds[key].name}" class="name">${obj.topGuilds[key].name}</div>`)
		users.scores.push(`<div class="value">${obj.topGuilds[key].score}</div>`)
		users.masters.push(`<div title="${obj.topGuilds[key].master.name}" class="name"><span>${obj.topGuilds[key].master.name}</span><sup>${obj.topGuilds[key].master.top}</sup></div>`)
	}

	// console.log(users)

	playersWrapper.innerHTML = `
			<div class="event__tabs_content_item player__column ">
				<div class="event__tabs_content_name name__column">
					name
				</div>
				${users.names.join('')}
			</div>
			<div class="event__tabs_content_item player__column align_center">
				<div class="event__tabs_content_name name__column">
				Score
				</div>
				${users.scores.join('')}
			</div>
			<div class="event__tabs_content_item player__column ">
				<div class="event__tabs_content_name name__column player-master">
					master<sup>TOP</sup>
				</div>
				${users.masters.join('')}
			</div>
		`
	tabsWrapper.append(players)
}