export function generatePlayersTab(obj) {

	const tabsWrapper = document.querySelector('.event__tabs_contents')

	let players = document.createElement('div')
	players.classList.add('event__tabs_content', 'players')

	let playersWrapper = document.createElement('div')
	playersWrapper.classList.add('event__tabs_content_wrap', 'players__wrapper')

	players.appendChild(playersWrapper)

	let users = { names: [], resets: [], levels: [] }
	for (const key in obj.topUsers) {
		users.names.push(`<div title="${obj.topUsers[key].name}" class="name">${obj.topUsers[key].name}</div>`)
		users.resets.push(`<div class="value">${obj.topUsers[key].reset}</div>`)
		users.levels.push(`<div class="value">${obj.topUsers[key].level}</div>`)
	}

	// console.log(users)

	playersWrapper.innerHTML = `
			<div class="event__tabs_content_item player__column">
				<div class="event__tabs_content_name name__column">
					name
				</div>
				${users.names.join('')}
			</div>
			<div class="event__tabs_content_item player__column">
				<div class="event__tabs_content_name name__column">
					resets
				</div>
				${users.resets.join('')}
			</div>
			<div class="event__tabs_content_item player__column">
				<div class="event__tabs_content_name name__column">
					levels
				</div>
				${users.levels.join('')}
			</div>
		`
	tabsWrapper.append(players)
}