
import { requestStatus } from "./requestStatus"

// window.onload = () => {
// 	document.write(((Array.from(document.getElementsByTagName("a"))).map(i => { return i.href })).join("<br>"))
// }

const allLinks = document.getElementsByTagName("a")

// console.log(allLinks)
export function checkServer(event) {
	// const request = new XMLHttpRequest()
	// // request.onload = reqListener
	// console.log(request)

	// request.open('GET', 'http://localhost:2000/index.html') //https://mukeeper.com/eventTime.php?ajax=true
	// request.send()
	// requestStatus.loading()
	// // console.log()
	// if (request.status == 200) { //&& request.readyState == 4
	// 	return
	// } else {
	// 	event.preventDefault()
	// 	requestStatus.fail()
	// }
	// for (const link of allLinks) {
	// 	console.log(`"${link.href}"`)
	// 	requestStatus.loading()
	// }



	// let response = fetch('http://localhost:2000/index.html', {
	// 	method: 'GET'
	// }).then(response => response.json()).then((value) => {
	// 	console.log(value)
	// }).catch(error => {
	// 	console.log(error)
	// 	event.preventDefault()
	// 	setTimeout(() => {
	// 		requestStatus.fail()

	// 	}, 300)
	// })
	// console.log(response)
}