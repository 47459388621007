export default function chengingBgImg() {
	console.log(document.cookie);
	//код на главной странице
	const thumbsImg = document.querySelectorAll('.chars__thumb');
	let mainBgImg = document.getElementById('mainBgImg');
	let id = 0;
	thumbsImg.forEach(charThumb => {
		charThumb.onclick = function (e) {
			if (e.target.closest('.chars__thumbs'))
				mainBgImg.src = e.target.getAttribute('data-images');
			document.cookie = "bg=" + e.target.getAttribute('data-class') + "; max-age=604800"
		}
	})
}